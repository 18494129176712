import React, { useCallback, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import {
  CheckboxInput,
  LanguageDropdown,
  Logo,
  IconFacebook,
  IconLinkedIn,
  EmailInput,
  PasswordInput,
} from '@btc-snxt/ui';
import { RouteList } from 'routeList';
import { Api } from 'api';
import { LoginRequest } from 'api/types/user/auth';
import { useAuth } from 'helpers/useAuth/useAuth';
import {
  StyledAuthBody,
  StyledAuthButton,
  StyledAuthHeader,
  StyledButtonWrapper,
  StyledCheckbox,
  StyledCheckboxBlock,
  StyledCheckboxText,
  StyledInput,
  StyledInputBlock,
  StyledLanguageSelect,
  StyledLink,
  StyledLogo,
  StyledSuggestion,
  StyledText,
  StyledTextBlock,
  StyledTextBody,
  StyledTextHeader,
  StyledTitle,
} from 'layouts/AuthenticationLayout/AuthenticationLayout.style';
import { StyledForgotPassword, StyledLogin, StyledSocialIconsBlock } from './Login.style';

const Login: React.FC = () => {
  const { t } = useTranslation();

  const { login } = useAuth();

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email().required(),
    password: Yup.string().required(),
    remember_me: Yup.boolean().oneOf([true, false]),
  });

  const [loginRequest] = useState({
    email: '',
    password: '',
    remember_me: false,
  });

  const onSubmit = useCallback(
    async (request: LoginRequest, helpers: FormikHelpers<LoginRequest>) => {
      try {
        const response = await Api.user.auth.login(request);

        if (response.access_token) {
          await login(response.access_token, response.permissions);
        }
      } catch (e: any) {
        helpers.setErrors(e.response?.errors);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history, t],
  );

  return (
    <>
      <StyledAuthHeader>
        <StyledLogo>
          <Link to={'/'}>
            <Logo />
          </Link>
        </StyledLogo>

        <StyledTitle>
          <StyledTextBlock>
            <StyledTextHeader>{t('auth.login.greeting')}</StyledTextHeader>

            <StyledTextBody>{t('auth.login.greeting_text')}</StyledTextBody>
          </StyledTextBlock>

          <StyledLanguageSelect>
            <LanguageDropdown />
          </StyledLanguageSelect>
        </StyledTitle>
      </StyledAuthHeader>

      <StyledAuthBody>
        <StyledLogin>
          <Formik initialValues={loginRequest} validationSchema={LoginSchema} onSubmit={onSubmit}>
            {({ handleSubmit, handleChange, values }) => (
              <Form onSubmit={handleSubmit}>
                <StyledInputBlock>
                  <StyledInput>
                    <StyledText>{t('auth.login.enter_email')}</StyledText>

                    <EmailInput
                      id={'email'}
                      name={'email'}
                      placeholder={t('label.email')}
                      onChange={handleChange}
                      value={values.email}
                    />
                  </StyledInput>

                  <StyledInput>
                    <StyledText>{t('auth.login.enter_password')}</StyledText>

                    <PasswordInput
                      id={'password'}
                      name={'password'}
                      placeholder={t('label.password')}
                      onChange={handleChange}
                      value={values.password}
                    />

                    <StyledForgotPassword>
                      <NavLink to={RouteList.AUTHENTICATION.FORGOT_PASSWORD.path}>
                        {t('auth.login.forgot_password')}
                      </NavLink>
                    </StyledForgotPassword>
                  </StyledInput>
                </StyledInputBlock>

                <StyledCheckboxBlock>
                  <StyledCheckbox>
                    <Field as={CheckboxInput} name={'rememberMe'} />

                    <StyledCheckboxText>{t('auth.login.remember_me')}</StyledCheckboxText>
                  </StyledCheckbox>
                </StyledCheckboxBlock>

                <StyledButtonWrapper>
                  <StyledAuthButton>{t('auth.login.login')}</StyledAuthButton>
                </StyledButtonWrapper>

                <StyledSuggestion>
                  <StyledText>{t('auth.login.bottom_text')}</StyledText>

                  <StyledLink>
                    <NavLink to={RouteList.AUTHENTICATION.REGISTER.path}>
                      {t('auth.login.sign_up')}
                    </NavLink>
                  </StyledLink>
                </StyledSuggestion>

                <StyledSocialIconsBlock>
                  {/*TODO: add login with Facebook functionality*/}
                  <NavLink to={'/login-facebook'}>
                    <IconFacebook />
                  </NavLink>

                  {/*TODO: add login with LinkedIn functionality*/}
                  <NavLink to={'login-linkedin'}>
                    <IconLinkedIn />
                  </NavLink>
                </StyledSocialIconsBlock>
              </Form>
            )}
          </Formik>
        </StyledLogin>
      </StyledAuthBody>
    </>
  );
};

export default Login;
