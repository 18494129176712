import React, { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { Theme, Global, ToastContainer } from '@btc-snxt/ui';
import Routes from 'pages/Routes';
import { useAppState } from 'modules/appState';
import { setupErrorAndWarningFilters } from 'helpers/error';
import { useAuth } from 'helpers/useAuth/useAuth';
import { Api } from 'api';

const App: React.FC = () => {
  const { isAppStarted } = useAppState();

  const { refresh, mount, dismount } = useAuth();

  useEffect(() => {
    refresh(Api.user.auth.refreshToken);

    mount();

    const { originalConsoleError, originalConsoleWarn } = setupErrorAndWarningFilters();

    return () => {
      dismount();

      console.error = originalConsoleError;
      console.warn = originalConsoleWarn;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={Theme}>
      <Global />
      {isAppStarted && <Routes />}
      <ToastContainer limit={5} />
    </ThemeProvider>
  );
};

export default App;
