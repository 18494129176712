import React from 'react';
import { useTranslation } from 'react-i18next';
import { PoweredBySeeNext } from '@btc-snxt/survey';
import { IconDoneAll } from '@btc-snxt/ui';
import {
  StyledMessagePage,
  StyledMessagePageBody,
  StyledIconWrapper,
  StyledText,
  StyledSubtext,
  StyledMessagePageFooter,
  StyledMessagePageHeader,
} from './MessageSuccess.style';

interface MessageSuccessProps {
  color: string;
}

const MessageSuccess: React.FC<MessageSuccessProps> = ({ color }) => {
  const { t } = useTranslation();

  return (
    <StyledMessagePage>
      <StyledMessagePageHeader />

      <StyledMessagePageBody>
        <StyledIconWrapper color={color}>
          <IconDoneAll />
        </StyledIconWrapper>

        <StyledText>{t('message.thank_you_title')}</StyledText>

        <StyledSubtext>{t('message.thank_you_text')}</StyledSubtext>
      </StyledMessagePageBody>

      <StyledMessagePageFooter>
        <PoweredBySeeNext />
      </StyledMessagePageFooter>
    </StyledMessagePage>
  );
};

export default MessageSuccess;
