import React from 'react';
import { StyledAuthBlock, StyledAuthenticationLayout } from './AuthenticationLayout.style';
import { Theme, GlobalBody } from '@btc-snxt/ui';

const AuthenticationLayout: React.FC = ({ children }) => {
  return (
    <>
      <GlobalBody color={Theme.colors.blue} />

      <StyledAuthenticationLayout>
        <StyledAuthBlock>{children}</StyledAuthBlock>
      </StyledAuthenticationLayout>
    </>
  );
};

export default AuthenticationLayout;
