import React from 'react';
import { Container, Row, H1 } from '@btc-snxt/ui';

const NotFound: React.FC = () => {
  return (
    <Container>
      <Row>
        <H1>Not Found</H1>
      </Row>
    </Container>
  );
};

export default NotFound;
