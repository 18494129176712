import React from 'react';
import usePermissions from 'helpers/usePermissions';
import { Navigate } from 'react-router-dom';
import { useLocation, matchPath } from 'react-router-dom';
import { RouteList } from 'routeList';

interface Props {
  component: React.FC<any> | React.ComponentClass<any>;
  layout: React.FC<any> | React.ComponentClass<any>;
  permissions: Array<string>;
}

const AuthPage: React.FC<Props> = ({ component: Component, layout: Layout, permissions }) => {
  const { doesNotHaveAny } = usePermissions();

  const { pathname } = useLocation();

  const isRedirect = (permissions: string | string[]): boolean => {
    const permissionList = Array.isArray(permissions) ? permissions : [permissions];
    return doesNotHaveAny(permissionList);
  };

  const isLoginPage = () => {
    return matchPath(pathname, RouteList.AUTHENTICATION.LOGIN.path);
  };

  if (isRedirect(permissions) && !isLoginPage()) {
    return <Navigate to={RouteList.AUTHENTICATION.LOGIN.path} />;
  }

  if (isRedirect(permissions) && isLoginPage()) {
    return <Navigate to={RouteList.DASHBOARD.path} />;
  }

  return (
    <Layout>
      <Component />
    </Layout>
  );
};
export default AuthPage;
