import Http from 'api/http';
import Client from 'api/services/client';
import { SurveyRequest } from 'api/types/survey';

class Survey extends Client {
  constructor(http: Http) {
    super(http);

    this.api = {
      SURVEY_GET: `${this.http.baseUrl}/app/{surveyId}`,
      SURVEY_POST: `${this.http.baseUrl}/app/{surveyUrl}`,
    };
  }

  get = (surveyId: string): Promise<any> => {
    return this.http.get(this.buildUrl(this.api.SURVEY_GET, { surveyId }));
  };

  post = (surveyUrl: string, data: SurveyRequest): Promise<any> => {
    return this.http.post(this.buildUrl(this.api.SURVEY_POST, { surveyUrl }), data);
  };
}

export default Survey;
