import React from 'react';

import { Theme, GlobalBody } from '@btc-snxt/ui';
import { StyledBody, StyledGenericLayout } from './GenericLayout.style';

const GenericLayout: React.FC = ({ children }) => (
  <>
    <GlobalBody color={Theme.colors.white} />
    <StyledGenericLayout>
      <StyledBody>{children}</StyledBody>
    </StyledGenericLayout>
  </>
);

export default GenericLayout;
