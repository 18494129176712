import Http from './http';
import Survey from './services/survey/survey';
import UserAuth from './services/user/auth';
import UserProfile from './services/user/profile';

export const http = new Http();
export { http as Http };

export const Api = {
  user: {
    auth: new UserAuth(http),
    profile: new UserProfile(http),
    survey: new Survey(http),
  },
};
