import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { format, parseISO } from 'date-fns';
import { buildYupValidations, buildYupLocale } from '@btc-snxt/system';
import { Http } from 'api';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      debug: false,
      react: {
        useSuspense: true, // Either this must be set to 'false' or App should be wrapped in Suspense component
      },
      backend: {
        // loadPath: `${process.env.BASE_URL}/language/{{lng}}`,
        loadPath: `${Http.baseUrl}/common/language/{{lng}}`,
        allowMultiLoading: false,
      },
      lng: 'en',
      fallbackLng: ['en', 'lt', 'ru'],
      supportedLngs: ['en', 'lt', 'ru'],
      saveMissing: true,
      missingKeyHandler: (lngs, ns, key) => {
        console.info('Missing key translation key', key, lngs);
      },
      interpolation: {
        escapeValue: false,
        formatSeparator: ',',
        format: function (value, formatting, lng, options) {
          // formatting date in translation file to specific format
          if (formatting && options && 'date' in options && options['date']) {
            const date = options['date'];
            return format(parseISO(date), formatting);
          }

          return value;
        },
      },
    },
    () => {
      buildYupLocale();
      buildYupValidations();
    },
  );

export default i18n;
