import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { StringParam, useQueryParam } from 'use-query-params';
import { RouteList } from 'routeList';
import { Api } from 'api';
import { Logo, LanguageDropdown, CenteredSpinner, error } from '@btc-snxt/ui';
import {
  StyledAuthBody,
  StyledAuthButton,
  StyledAuthHeader,
  StyledButtonWrapper,
  StyledLanguageSelect,
  StyledLogo,
  StyledTextBlock,
  StyledTextBody,
  StyledTitle,
} from 'layouts/AuthenticationLayout/AuthenticationLayout.style';
import { StyledSuccessMessage } from '../ForgotPassword/ForgotPassword.style';

const VerifyEmail: React.FC = () => {
  const { t } = useTranslation();

  const [verifyLink] = useQueryParam('link', StringParam);

  const [success, setSucces] = useState<boolean>(false);

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        await Api.user.auth.verifyEmail(verifyLink);
      } catch (e: any) {
        error(e);
      } finally {
        setSucces(true);
      }
    };

    verifyEmail().catch((e) => error(e));
  }, [verifyLink]);

  return (
    <>
      <StyledAuthHeader>
        <StyledLogo>
          <Link to={'/'}>
            <Logo />
          </Link>
        </StyledLogo>

        <StyledTitle>
          <StyledTextBlock>
            <StyledTextBody>{t('auth.register.email_verification')}</StyledTextBody>
          </StyledTextBlock>

          <StyledLanguageSelect>
            <LanguageDropdown />
          </StyledLanguageSelect>
        </StyledTitle>
      </StyledAuthHeader>
      <StyledAuthBody>
        {success ? (
          <>
            <StyledSuccessMessage>{t('auth.register.verify_success')}</StyledSuccessMessage>

            <StyledButtonWrapper>
              <NavLink to={RouteList.AUTHENTICATION.LOGIN.path}>
                <StyledAuthButton>{t('auth.login.login')}</StyledAuthButton>
              </NavLink>
            </StyledButtonWrapper>
          </>
        ) : (
          <CenteredSpinner />
        )}
      </StyledAuthBody>
    </>
  );
};

export default VerifyEmail;
