import styled from 'styled-components';
import { breakpointDown, IconDoneAll } from '@btc-snxt/ui';

export const StyledMessagePage = styled.div`
  align-items: center;
  background-color: rgba(95, 1, 209, 0.03);
  color: ${({ theme }) => theme.colors.black2};
  display: flex;
  flex-direction: column;
  font-family: 'Inter', sans-serif;
  font-size: 2.25rem;
  font-weight: 500;
  height: 100%;
  justify-content: space-between;
  line-height: 2.75rem;

  ${breakpointDown.MD} {
    font-size: 1.625rem;
  }
`;

export const StyledMessagePageHeader = styled.div``;

export const StyledMessagePageBody = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 1.25rem;
`;

export const StyledMessagePageFooter = styled.div`
  display: flex;
  justify-content: end;
  padding: 3rem 5rem;
  width: 100%;

  color: rgba(0, 0, 0, 0.4);

  i {
    background-color: rgba(0, 0, 0, 0.4);
  }
  ${breakpointDown.MD} {
    padding: 2.5rem 1rem;
  }
`;

interface StyledIconWrapperProps {
  color: string;
}

export const StyledIconWrapper = styled.div<StyledIconWrapperProps>`
  ${IconDoneAll} {
    height: 8rem;
    width: 8rem;
    background-color: ${(props) => props.color};

    ${breakpointDown.MD} {
      height: 4.5rem;
      width: 4.5rem;
    }
  }
`;

export const StyledText = styled.div`
  margin-top: 3.125rem;
  text-align: center;

  ${breakpointDown.MD} {
    margin-top: 2rem;
  }
`;

export const StyledSubtext = styled.div`
  color: ${({ theme }) => theme.colors.black3};
  font-family: 'Manrope';
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.875rem;
  margin-top: 1.375rem;
  max-width: 43rem;
  text-align: center;
`;
