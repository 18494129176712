import Client from 'api/services/client';
import Http from 'api/http';
import { EmptyResponse } from 'api/types/common';
import {
  ConfirmResetPasswordRequest,
  LoginRequest,
  LoginUserResponse,
  RefreshUserResponse,
  RegisterRequest,
  ResetPasswordRequest,
} from 'api/types/user/auth';

class Auth extends Client {
  constructor(http: Http) {
    super(http);

    this.api = {
      LOGIN: `${this.http.baseUrl}/auth/login`,
      REGISTER: `${this.http.baseUrl}/auth/register`,
      REFRESH: `${this.http.baseUrl}/auth/refresh`,
      LOGOUT: `${this.http.baseUrl}/auth/logout`,
      RESET_PASSWORD: `${this.http.baseUrl}/auth/reset-password`,
      CONFIRM_RESET_PASSWORD: `${this.http.baseUrl}/auth/confirm-reset-password`,
      VERIFY_EMAIL: `${this.http.baseUrl}/email/verify/{verify_link}`,
    };
  }

  login = (data: LoginRequest): Promise<LoginUserResponse> => {
    return this.http.post(this.api.LOGIN, data);
  };

  refreshToken = (): Promise<RefreshUserResponse> => {
    return this.http.put(this.api.REFRESH);
  };

  logout = (): Promise<any> => {
    return this.http.post(this.api.LOGOUT);
  };

  resetPassword = (data: ResetPasswordRequest): Promise<EmptyResponse> => {
    return this.http.post(this.api.RESET_PASSWORD, data);
  };

  confirmResetPassword = (data: ConfirmResetPasswordRequest): Promise<EmptyResponse> => {
    return this.http.post(this.api.CONFIRM_RESET_PASSWORD, data);
  };

  register = (data: RegisterRequest): Promise<LoginUserResponse> => {
    return this.http.post(this.api.REGISTER, data);
  };

  verifyEmail = (verify_link: any): Promise<any> => {
    return this.http.get(this.buildUrl(this.api.VERIFY_EMAIL, { verify_link }));
  };
}

export default Auth;
