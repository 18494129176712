import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FixedCenteredSpinner, Locale, error } from '@btc-snxt/ui';
import {
  LinearProgressBar,
  NavigationButtons,
  PoweredBySeeNext,
  Previewer,
  StyledPreviewerFooter,
  SurveyItemType,
  SurveyType,
  TemplateItem,
  getSurveyItemIndex,
  getSurveyItemsSize,
  hexToRGB,
  useSurveyNavigationStore,
} from '@btc-snxt/survey';
import { Api } from 'api';
import { SurveyItem } from 'api/types/survey';
import { RouteList } from 'routeList';
import {
  PreviewerItemWrapper,
  StyledItems,
  SurveyAppFooter,
  SurveyAppFooterWrapper,
  SurveyAppHeader,
  SurveyAppHeaderWrapper,
} from './Survey.style';
import { MessageSuccess } from '../../../components/MessageSuccess';
import { useSurveyPreviewerStore } from '@btc-snxt/survey';
import { useEnterKeyHandler, useWheelHandler } from 'helpers/events';
import { useScrollHandler } from 'helpers/events/KeyEventsManager';

const Survey: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { surveyUrl } = useParams();
  const navigate = useNavigate();
  useEnterKeyHandler();
  useScrollHandler();
  useWheelHandler();

  const currentItem = useSurveyNavigationStore();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [items, setItems] = useState<TemplateItem[]>([]);
  const [logo, setLogo] = useState<string>('');
  const [locale, setLocale] = useState<string>(i18n.languages[0]);
  const [availableLanguages, setAvailableLanguages] = useState<Array<string>>([]);
  const [success, setSuccess] = useState<boolean>(false);
  const [surveyType, setSurveyType] = useState<SurveyType>(SurveyType.IndividualSurvey);
  const [hasIntro, setHasIntro] = useState<boolean>(false);

  const fetchSurvey = async (surveyUrl: string) => {
    const data = await Api.user.survey.get(surveyUrl);
    if (data.questions === undefined) {
      return [];
    }

    setAvailableLanguages(data.available_languages);
    setLocale(
      data.available_languages.includes(Locale.LT) ? Locale.LT : data.available_languages[0],
    );
    const fetchedSurveyItems = data.questions;
    setLogo(data.logo);
    setSurveyType(data.surveyType);
    const surveyItems: TemplateItem[] = [];
    fetchedSurveyItems.forEach((item: any) => {
      const surveyItem: TemplateItem = {
        id: item.id,
        type: item.type,
        initialValues: item.design_traits,
      };

      surveyItems.push(surveyItem);
    });
    return surveyItems;
  };

  useEffect(() => {
    (async () => {
      try {
        const items = await fetchSurvey(surveyUrl as string);
        setItems(items);
        setIsLoading(false);
      } catch (e) {
        navigate(RouteList.ERROR.path);
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyUrl]);

  useEffect(() => {
    i18n.changeLanguage(locale);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  const { questions } = useSurveyPreviewerStore();

  const submit = async () => {
    let canSubmit = true;

    for (let i = 0; i < questions.length; i++) {
      const question = questions[i];
      const errs = await question.validate();
      if (errs.answers || errs.questions || errs.email) {
        canSubmit = false;
      }
    }

    if (!canSubmit) {
      const foundElement = document.querySelector('div[title="error"]');
      if (foundElement) {
        const previousElement = foundElement.parentElement!.parentElement;
        if (previousElement) {
          const errorId =
            previousElement.parentElement!.parentElement!.parentElement!.parentElement!
              .parentElement!.id;
          currentItem.setCurrentSurveyQuestion({
            state: Number(errorId),
          });
          previousElement.scrollIntoView({ behavior: 'smooth' });
        }
      }
      return;
    }

    const surveyItems: SurveyItem[] = [];

    for (const item of items) {
      const surveyItem: SurveyItem = {
        id: item.id,
        type: item.type,
        required: item.initialValues.required ? item.initialValues.required.data : false,
      };

      if (item.value != undefined) {
        surveyItem.values = Array.isArray(item.value) ? item.value : [item.value];
      }
      surveyItems.push(surveyItem);
    }

    try {
      await Api.user.survey.post(surveyUrl as string, { questions: surveyItems });
      setSuccess(true);
    } catch (e) {
      error(t('common.error'));
    }
  };

  const findItemIndex = (id: string): number => {
    return items.findIndex((x) => x.id === id);
  };

  const handleOnValuesChanged = (id: string, values: any) => {
    const index = findItemIndex(id);
    if (index === -1) {
      return;
    }
    items[index].value = values;
  };

  useEffect(() => {
    items.forEach((item) => {
      if (item.type === SurveyItemType.Intro) {
        setHasIntro(true);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getScreenInnerHeight = useMemo(() => {
    return `${window.innerHeight}px`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerHeight]);

  return (
    <>
      {isLoading && <FixedCenteredSpinner />}
      {!isLoading && success ? (
        <MessageSuccess color={items[0].initialValues?.color} />
      ) : (
        !isLoading && (
          <StyledItems>
            <SurveyAppHeaderWrapper>
              <SurveyAppHeader
                $backgroundColor={
                  items.length > 0 ? hexToRGB(items[0].initialValues?.color, '0.03') : '#FFFFF'
                }
              >
                <LinearProgressBar
                  color={items.length > 0 ? items[0].initialValues?.color : '#FFFFF'}
                  currentValue={
                    currentItem.currentSurveyQuestion -
                    getSurveyItemIndex(items, currentItem.currentSurveyQuestion)
                  }
                  total={getSurveyItemsSize(items)}
                />
              </SurveyAppHeader>
            </SurveyAppHeaderWrapper>
            {items.map((item, index) => (
              <PreviewerItemWrapper
                key={item.id}
                id={String(hasIntro ? index - 1 : index)}
                height={getScreenInnerHeight}
              >
                <Previewer
                  id={item.id}
                  values={item.values ?? item.initialValues}
                  type={item.type}
                  surveyType={surveyType}
                  onValuesChanged={handleOnValuesChanged}
                  value={item.value}
                  locale={locale}
                  color={item.initialValues.color}
                  size={getSurveyItemsSize(items)}
                  questionIndex={index - getSurveyItemIndex(items, index)}
                  itemIndex={index}
                  submit={submit}
                  logo={logo}
                  logoObject={''}
                  setLocale={setLocale}
                  availableLanguages={availableLanguages}
                />
              </PreviewerItemWrapper>
            ))}
            <SurveyAppFooterWrapper>
              <SurveyAppFooter
                $backgroundColor={
                  items.length > 0 ? hexToRGB(items[0].initialValues?.color, '0.03') : '#FFFFF'
                }
              >
                <StyledPreviewerFooter navigation={true}>
                  <NavigationButtons />
                  <PoweredBySeeNext />
                </StyledPreviewerFooter>
              </SurveyAppFooter>
            </SurveyAppFooterWrapper>
          </StyledItems>
        )
      )}
    </>
  );
};

export default Survey;
