import { create } from 'zustand';

interface AppState {
  permissions: string[];
  setPermissions: (permissions: string[]) => void;
  resetPermissions: () => void;

  isAppStarted: boolean;
  setAppStarted: (isAppLoading: boolean) => void;
}

const useAppState = create<AppState>()((set) => ({
  permissions: [],
  setPermissions: (permissions) => set(() => ({ permissions })),
  resetPermissions: () => set(() => ({ permissions: [] })),

  isAppStarted: false,
  setAppStarted: (isAppLoading) => set({ isAppStarted: isAppLoading }),
}));

export default useAppState;
