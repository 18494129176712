import styled from 'styled-components';

export const StyledGenericLayout = styled.div`
  height: 100%;

  // Works only on Firefox
  * {
    scrollbar-width: thin;
  }

  // Works only on Chrome, Edge, Safari and Opera
  *::-webkit-scrollbar {
    width: 0.125rem;
    height: 0.125rem;
  }
  *:hover::-webkit-scrollbar {
    width: 0.375rem;
    height: 0.375rem;
  }

  *::-webkit-scrollbar-track {
    background: transparent;
  }

  *::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.gray1};
    border-radius: 1.25rem;
  }
`;

export const StyledBody = styled.div`
  overflow: overlay;
  transition: 0.2s;
  height: 100%;
  width: 100%;

  // if browser is firefox...
  @-moz-document url-prefix() {
    overflow: auto;
  }
`;
