import { useSurveyNavigationStore, getElementInCenter } from '@btc-snxt/survey';
import { useEffect } from 'react';

export const useEnterKeyHandler = () => {
  const surveyItem = useSurveyNavigationStore();
  useEffect(() => {
    const keyDownHandler = (event: any) => {
      const currentElement = getElementInCenter();
      if (event.key === 'Enter') {
        const elements = document.querySelectorAll('div[id]:not(#root)');
        elements.forEach((element, index) => {
          const id = elements[index].getAttribute('id');
          if (!isNaN(Number(id))) {
            const elementRect = element.getBoundingClientRect();
            if (
              elementRect.top >= 0 &&
              elementRect.left >= 0 &&
              elementRect.bottom - 1 <=
                (window.innerHeight || document.documentElement.clientHeight) &&
              elementRect.right - 1 <= (window.innerWidth || document.documentElement.clientWidth)
            ) {
              const foundElement = document.getElementById(String(Number(element.id) + 1));
              if (foundElement) {
                foundElement.scrollIntoView({ behavior: 'smooth' });
                surveyItem.setCurrentSurveyQuestion({
                  state: currentElement + 1,
                });
              } else {
                const submitButton = document.getElementById('submit');
                if (submitButton) {
                  submitButton.click();
                }
              }
            }
          }
        });
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const UseChildWheelHandler = (event: any, scrollContainer: HTMLElement) => {
  event.stopPropagation();
  event.preventDefault();
  const isScrollable = scrollContainer.scrollHeight > scrollContainer.clientHeight;
  if (isScrollable) {
    const deltaY = event.deltaY;
    // Check if scrolling up and reached top of the container
    if (deltaY < 0 && scrollContainer.scrollTop === 0) {
      return;
    }
    // Check if scrolling down and reached bottom of the container
    if (
      deltaY > 0 &&
      scrollContainer.scrollTop + scrollContainer.clientHeight === scrollContainer.scrollHeight
    ) {
      return;
    }
    // Scroll by a certain amount based on the scroll direction
    const scrollAmount = 20; // Adjust this value to control the scrolling speed
    scrollContainer.scrollTop += deltaY > 0 ? scrollAmount : -scrollAmount;
  }
};

export const useWheelHandler = () => {
  const surveyItem = useSurveyNavigationStore();
  useEffect(() => {
    const wheelHandler = (event: any) => {
      const elementsWithChildScroll = document.getElementsByClassName('scroll-container');
      for (let i = 0; i < elementsWithChildScroll.length; i++) {
        const element = elementsWithChildScroll[i];
        const handleMouseWheel = (e: any) => {
          UseChildWheelHandler(e, element as HTMLElement);
        };
        element.addEventListener('mouseenter', () => {
          event.stopPropagation();
          document.removeEventListener('mousewheel', wheelHandler);
          element.addEventListener('mousewheel', handleMouseWheel, { passive: false });
          return;
        });
        element.addEventListener('mouseleave', () => {
          element.removeEventListener('mousewheel', handleMouseWheel);
          document.addEventListener('mousewheel', wheelHandler, { passive: false });
          return;
        });
      }
      const currentElement = getElementInCenter();
      event.preventDefault();
      const elements = document.querySelectorAll('div[id]:not(#root)');
      elements.forEach((element, index) => {
        const id = elements[index].getAttribute('id');
        if (!isNaN(Number(id))) {
          const elementRect = element.getBoundingClientRect();
          if (
            elementRect.top >= 0 &&
            elementRect.left >= 0 &&
            elementRect.bottom - 1 <=
              (window.innerHeight || document.documentElement.clientHeight) &&
            elementRect.right - 1 <= (window.innerWidth || document.documentElement.clientWidth)
          ) {
            const delta = Math.sign(event.deltaY);
            if (delta < 0) {
              const foundElement = document.getElementById(String(Number(element.id) - 1));
              if (foundElement) {
                surveyItem.setCurrentSurveyQuestion({
                  state: currentElement - 1,
                });
                foundElement.scrollIntoView({ behavior: 'smooth' });
              }
            } else if (delta > 0) {
              const foundElement = document.getElementById(String(Number(element.id) + 1));
              if (foundElement) {
                surveyItem.setCurrentSurveyQuestion({
                  state: currentElement + 1,
                });
                foundElement.scrollIntoView({ behavior: 'smooth' });
              }
            }
          }
        }
      });
    };
    document.addEventListener('mousewheel', wheelHandler, { passive: false });
    return () => {
      document.removeEventListener('mousewheel', wheelHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const useScrollHandler = () => {
  const surveyItem = useSurveyNavigationStore();
  useEffect(() => {
    let scrollTimeout: NodeJS.Timeout | null = null;

    const handleTouch = () => {
      if (scrollTimeout) {
        clearTimeout(scrollTimeout);
      }

      scrollTimeout = setTimeout(() => {
        surveyItem.setCurrentSurveyQuestion({
          state: getElementInCenter(),
        });
        scrollTimeout = null;
      }, 200); // Adjust the delay as needed
    };

    document.addEventListener('touchend', handleTouch);

    // Cleanup function
    return () => {
      if (scrollTimeout) {
        clearTimeout(scrollTimeout);
      }
      document.removeEventListener('touchend', handleTouch);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
