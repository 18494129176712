import React from 'react';

import { Dashboard } from 'pages/Common/Dashboard';
import { NotFound } from 'pages/Common/NotFound';
import { Login } from 'pages/Authentication/Login';
import { AuthenticationLayout, GenericLayout } from 'layouts';
import { ForgotPassword } from './pages/Authentication/ForgotPassword';
import { Register } from './pages/Authentication/Register';
import { ConfirmPassword } from './pages/Authentication/ConfirmPassword';
import { VerifyEmail } from './pages/Authentication/VerifyEmail';
import { Survey } from './pages/Common/Survey';
import { MessageFail } from 'pages/Common/Messages/MessageFail';

export interface SiteRoute {
  readonly path: string;
  readonly component: React.FC<any> | React.ComponentClass<any>;
  readonly layout: React.FC<any> | React.ComponentClass<any>;
  readonly permissions: Array<string>;
}

export type RouteTree = { [key: string]: SiteRoute | RouteTree };

type RouteTreeProps<T> = {
  [K in keyof T]: T[K] extends RouteTree ? RouteTreeProps<T[K]> : T[K];
};

const createRouteTree = <T extends RouteTree>(item: T): RouteTreeProps<T> => {
  return item;
};

const RouteList = createRouteTree({
  DASHBOARD: {
    path: '/dashboard',
    permissions: [],
    layout: GenericLayout,
    component: Dashboard,
  },
  SURVEY: {
    path: '/:surveyUrl',
    permissions: [],
    layout: GenericLayout,
    component: Survey,
  },
  AUTHENTICATION: {
    LOGIN: {
      path: '/login',
      permissions: [],
      layout: AuthenticationLayout,
      component: Login,
    },
    FORGOT_PASSWORD: {
      path: '/forgot-password',
      permissions: [],
      layout: AuthenticationLayout,
      component: ForgotPassword,
    },
    CONFIRM_PASSWORD_RESET: {
      path: '/confirm-password-reset',
      permissions: [],
      layout: AuthenticationLayout,
      component: ConfirmPassword,
    },
    REGISTER: {
      path: '/register',
      permissions: [],
      layout: AuthenticationLayout,
      component: Register,
    },
    VERIFY_EMAIL: {
      path: '/verify-email',
      permissions: [],
      layout: AuthenticationLayout,
      component: VerifyEmail,
    },
  },
  ERROR: {
    path: '/error',
    permissions: [],
    layout: GenericLayout,
    component: NotFound,
  },
  FAIL: {
    path: '/fail',
    permissions: [],
    layout: GenericLayout,
    component: MessageFail,
  },
});

export { RouteList };
