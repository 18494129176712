import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  StyledFailText,
  StyledMessagePage,
  StyledPageBody,
  StyledPageHeader,
} from './MessagesFail.style';

const MessageFail: React.FC = () => {
  const { t } = useTranslation();

  return (
    <StyledMessagePage>
      <StyledPageHeader>{t('message.fail_title')}</StyledPageHeader>
      <StyledPageBody>
        <StyledFailText>{t('message.fail_text')}</StyledFailText>
      </StyledPageBody>
    </StyledMessagePage>
  );
};

export default MessageFail;
