import styled from 'styled-components';
import { StyledPreviewer } from '@btc-snxt/survey';
import { breakpointDown } from '@btc-snxt/ui';

export const StyledItems = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: overlay;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  height: 100vh;

  // if browser is firefox...
  @-moz-document url-prefix() {
    overflow: auto;
  }
`;

interface PreviewerItemWrapperProps {
  height: string;
}

interface BackgroundColorProps {
  $backgroundColor: string;
}

export const PreviewerItemWrapper = styled.div<PreviewerItemWrapperProps>`
  scroll-snap-align: start;

  ${breakpointDown.LG} {
    ${StyledPreviewer} {
      padding-bottom: calc(100vh - ${(prop) => prop.height} + 2rem);
    }
  }
`;

export const SurveyAppHeaderWrapper = styled.div`
  position: absolute;
  display: flex;
  z-index: 5;
  width: 100%;
  background-color: white;
`;

export const SurveyAppHeader = styled.div<BackgroundColorProps>`
  width: 100%;
  padding: 3rem;
  background-color: ${(props) => props.$backgroundColor};
`;

export const SurveyAppFooterWrapper = styled.div`
  position: absolute;
  display: flex;
  z-index: 5;
  width: 100%;
  background-color: white;
  bottom: 0;
`;

export const SurveyAppFooter = styled.div<BackgroundColorProps>`
  width: 100%;
  height: 100%;
  padding: 3rem;
  bottom: 0;
  display: flex;
  background-color: ${(props) => props.$backgroundColor};
`;
