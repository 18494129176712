import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Cookies, CookiesProvider } from 'react-cookie';
import { QueryParamProvider } from 'use-query-params';
import 'services/i18n';
import { RouteAdapter } from 'components/RouteAdapter';
import reportWebVitals from './reportWebVitals';
import App from './App';

export const cookies = new Cookies();

ReactDOM.render(
  <React.StrictMode>
    <React.Suspense fallback={'Loading'}>
      <CookiesProvider cookies={cookies}>
        <BrowserRouter>
          <QueryParamProvider ReactRouterRoute={RouteAdapter as unknown as React.FunctionComponent}>
            <App />
          </QueryParamProvider>
        </BrowserRouter>
      </CookiesProvider>
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
