import Client from 'api/services/client';
import Http from 'api/http';
import { UpdateProfileRequest, UserProfileResponse } from 'api/types/user/profile';

class Profile extends Client {
  constructor(http: Http) {
    super(http);

    this.api = {
      PROFILE: `${this.http.baseUrl}/profile`,
      UPDATE: `${this.http.baseUrl}/profile/update`,
    };
  }

  fetch = (): Promise<UserProfileResponse> => {
    return this.http.get(this.api.PROFILE);
  };

  update = (data: UpdateProfileRequest): Promise<UserProfileResponse> => {
    return this.http.put(this.api.UPDATE, data);
  };
}

export default Profile;
